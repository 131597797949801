import React from "react";
import PropTypes from "prop-types";

const ProInstallScreen = ({ classes, screenshot }) => {
  const {
    pic,
    pic2x,
    picXl,
    picXl2x,
    picTab,
    picTab2x,
    picMob,
    picMob2x,
    webp,
    webp2x,
    webpXl,
    webpXl2x,
    webpTab,
    webpTab2x,
    webpMob,
    webpMob2x,
  } = screenshot;

  return picXl ? (
    <picture>
      <source
        type="image/webp"
        media="(min-width: 1280px)"
        srcSet={`${webp} 1x, ${webp2x} 2x`}
      />
      <source media="(min-width: 1280px)" srcSet={`${pic} 1x, ${pic2x} 2x`} />
      <source
        type="image/webp"
        media="(min-width: 992px) and (max-width: 1279px)"
        srcSet={`${webpXl} 1x, ${webpXl2x} 2x`}
      />
      <source
        media="(min-width: 992px) and (max-width: 1279px)"
        srcSet={`${picXl} 1x, ${picXl2x} 2x`}
      />
      <source
        type="image/webp"
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={`${webpTab} 1x, ${webpTab2x} 2x`}
      />
      <source
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={`${picTab} 1x, ${picTab2x} 2x`}
      />
      <source
        type="image/webp"
        media="(max-width: 767px)"
        srcSet={`${webpMob} 1x, ${webpMob2x} 2x`}
      />
      <source
        media="(max-width: 767px)"
        srcSet={`${picMob} 1x, ${picMob2x} 2x`}
      />
      <img src={pic} alt="" loading="lazy" className={classes} />
    </picture>
  ) : (
    <picture>
      <source
        type="image/webp"
        media="(min-width: 1280px)"
        srcSet={`${webp} 1x, ${webp2x} 2x`}
      />
      <source media="(min-width: 1280px)" srcSet={`${pic} 1x, ${pic2x} 2x`} />
      <source
        type="image/webp"
        media="(min-width: 768px) and (max-width: 1279px)"
        srcSet={`${webpTab} 1x, ${webpTab2x} 2x`}
      />
      <source
        media="(min-width: 768px) and (max-width: 1279px)"
        srcSet={`${picTab} 1x, ${picTab2x} 2x`}
      />
      <source
        type="image/webp"
        media="(max-width: 767px)"
        srcSet={`${webpMob} 1x, ${webpMob2x} 2x`}
      />
      <source
        media="(max-width: 767px)"
        srcSet={`${picMob} 1x, ${picMob2x} 2x`}
      />
      <img src={pic} alt="" loading="lazy" className={classes} />
    </picture>
  );
};

ProInstallScreen.propTypes = {
  classes: PropTypes.string,
  screenshot: PropTypes.object,
};

export default ProInstallScreen;
