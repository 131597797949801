import React from "react";
import TrackedLink from "../../Analytics/TrackedLink";
import PROINSTALL_CONTENT from "../../common/ProInstall/index.content";
import ProInstallScreen from "./ProInstallScreen";
import "./index.scss";

const ProInstall = () => {
  const { pic1, pic2, pic3 } = PROINSTALL_CONTENT;
  const url =
    "https://chrome.google.com/webstore/detail/amazon-product-finder-amz/njopapoodmifmcogpingplfphojnfeea?utm_source=site&utm_medium=amzscout&utm_campaign=super_url_lp";

  return (
    <section className="PgASU-PI">
      <div className="container">
        <h2 className="PgASU-PI__title">
          Install AMZScout PRO Extension in 3 clicks!
        </h2>
        <p className="PgASU-PI__subtitle">
          Get detailed analytics on products from Amazon in real-time
        </p>
        <div className="PgASU-PI__list PgASU-PI-list">
          <div className="PgASU-PI-list__item">
            <ProInstallScreen classes="PgASU-PI-list__img" screenshot={pic1} />
            <p className="PgASU-PI-list__num">01.</p>
            <p className="PgASU-PI-list__text PgASU-PI-list-text">
              Go to the{" "}
              <TrackedLink
                category="Index"
                action="CTA"
                target
                class="PgASU-PI-list-text__link"
                label="PRO Extension for Chrome"
                path={url}
              >
                PRO Extension
              </TrackedLink>{" "}
              page in the Chrome Store
            </p>
            <TrackedLink
              category="Index"
              action="CTA"
              target
              class="PgASU-PI-list__btn"
              label="PRO Extension for Chrome"
              path={url}
            >
              Click here
            </TrackedLink>
          </div>
          <div className="PgASU-PI-list__item">
            <ProInstallScreen classes="PgASU-PI-list__img" screenshot={pic2} />
            <p className="PgASU-PI-list__num">02.</p>
            <p className="PgASU-PI-list__text">
              Click the “Add” button and wait three seconds
            </p>
          </div>
          <div className="PgASU-PI-list__item">
            <ProInstallScreen classes="PgASU-PI-list__img" screenshot={pic3} />
            <p className="PgASU-PI-list__num">03.</p>
            <p className="PgASU-PI-list__text">
              AMZScout PRO Extension is added to your Chrome or Edge browser -
              browse products on the Amazon site and get detailed analytics
              right on the search page
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProInstall;
